html {
  scroll-behavior: smooth;
}
body {
  padding: 0;
  margin: 0;
}
.container {
  position: relative;
  margin-top: 100px;
}
.container img {
  display: block;
  width: 100%;
}
nav {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  font-family: "Montserrat", sans-serif;
  padding: 0 5%;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.5);
}
nav .logo {
  float: left;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 42px;
  color: #fff;
}
nav .logo1 {
  float: right;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 42px;
  color: #fff;
}
nav .links {
  float: center;
  padding: 0;
  margin: 0px 0px -62px 0px;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
nav .linkobtn {
  float: right;
  padding: 0;
  margin: 0;
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
nav .links li {
  list-style: none;
}
nav .links a {
  display: block;
  padding: 1em;
  font-size: 19px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

#hulilogo{
  width: 18%;
  height: 100;
}

.buy-btn{
  float: right !important;
}

#nav-toggle {
  position: absolute;
  top: -100px;
}
nav .icon-burger {
  display: none;
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}
nav .icon-burger .line {
  width: 30px;
  height: 5px;
  background-color: #fff;
  margin: 5px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 1295px) {
  nav .logo {
    float: left;
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #fff;
  }
  nav .links {
    float: right;
    padding: 0;
    margin: 0;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  nav .links li {
    list-style: none;
  }
  nav .links a {
    display: block;
    padding: 1em;
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
  }
}
@media screen and (max-width: 995px) {
  nav .logo {
    float: none;
    width: auto;
    justify-content: center;
  }
  nav .links {
    float: none;
    position: fixed;
    z-index: 9;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 100%;
    width: auto;
    height: auto;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;
  }
  nav .links a {
    font-size: 22px;
  }
  nav :checked ~ .links {
    bottom: 0;
  }
  nav .icon-burger {
    display: block;
    z-index: 11;
  }
  nav :checked ~ .icon-burger .line:nth-child(1) {
    transform: translateY(10px) rotate(225deg);
  }
  nav :checked ~ .icon-burger .line:nth-child(3) {
    transform: translateY(-10px) rotate(-225deg);
  }
  nav :checked ~ .icon-burger .line:nth-child(2) {
    opacity: 0;
  }
}

.nav-dropdown {
  position: relative;
  display: none;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 1);

  border-radius: 0px 0px 10px 10px ;
}
.active{
  position: absolute;
  display: block;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);

}
@media screen and (max-width: 995px) {
  .nav__submenu {
    font-weight: 300;
    text-transform: none;
    display: block;
    position: relative;
    width: 60px;
    
  }

}

.nav__submenu {
  display: none;
  padding-top: 2px;
  border-radius: 0px 0px 5px 5px;
}

.nav__menu-item:hover .nav__submenu{
  
    display: block;
  
}
.nav__menu-item {
  display: inline-block;
position: relative;
}
    .nav__submenu {
      display: block;
    }
  

    
.nav__submenu {
  font-weight: 300;
  text-transform: none;
  display: none;
  position: absolute;
  width: 190px;
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: 0px !important;
}
@media screen and (max-width: 995px){

.nav__submenu {
  font-weight: 300;
  text-transform: none;
  display: none;
  position: absolute;
  z-index: 99;
  width: 216n mpx;
  background-color: rgb(0, 0, 0);
  padding-left: 0px !important;
}

  
}
    
.nav__submenu-item:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.down {
  transform: rotate(45deg);
}
.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px 5px 5px 5px;
  margin-bottom: 5px;
  margin-left: 2px;
}
